import React from "react"
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components"

const Success = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Software Engineer to Product Manager: Learn to make the switch with eng2pm</title>
    </Helmet>
    <GlobalStyle />
    <Body>
      <Container>
        <ValueProp>Thank you!</ValueProp>
        <p>A team member from eng2pm will reach out to you shortly.</p>
      </Container>
    </Body>
    <ContactLink href="mailto:help@akasihealth.com">Contact</ContactLink>
    <Footer>&#169; 2020 eng2pm</Footer>
  </>)

export default Success

const GlobalStyle = createGlobalStyle`
  body { 
    background-color: #cbaf87;
    font-family: 'Noto Sans JP', sans-serif;
    margin: 0;
  }
  * {
    box-sizing: border-box;
    font-family: 'Noto Sans JP', sans-serif;
  }
`;

const Body = styled.div`
  align-items: center;
  background: rgb(64,153,171);
  background: #e7dec8;
  color: #30475e;
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  justify-content: center;
`;

const Container = styled.div`
  max-width: 700px;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 0.8em;
  letter-spacing: 1px;
  margin: 0;
  text-transform: uppercase;
`;

const Primary = styled.span`
  color: #30475e;
`;

const Light = styled.span`
  font-weight: 300;
`;

const ValueProp = styled.h2`
  font-size: 2.25em;
  margin: 24px 0;
  margin-top: 0;
  text-align: left;
`;

const List = styled.ul`
  list-style-type: none;
  text-align: left;
`;

const ListItem = styled.li`
  margin-bottom: 24px;
`;

const ListHeader = styled.h3`
  margin: 0;
  text-transform: uppercase;
`;

const ListText = styled.p`
  font-size: 0.9em;
  font-weight: 300;
  margin: 0;
`;

const CTAForm = styled.div`
  display: block;
  margin: 0 auto;
  width: 320px;
`;

const CTACopy = styled.p``;

const StyledInput = styled.input`
  border: none;
  border-radius: 0;
  display: block;
  padding: 12px;
  width: 100%;
`;

const StyledButton = styled.button`
  background-color: #db6400;
  border: none;
  border-radius: 0;
  color: #f8f1f1;
  display: block;
  font-weight: 700;
  margin-top: 9px;
  padding: 12px;
  text-transform: uppercase;
  width: 100%;
`;

const ContactLink = styled.a`
  color: #db6400;
  display: block;
  font-size: 0.75em;
  font-weight: 600;
  margin-top: 24px;
  text-align: center;
  text-transform: uppercase;
`;

const Footer = styled.p`
  color: #30475e;
  display: block;
  font-size: 0.75em;
  margin-top: 24px;
  text-align: center;
`;
